import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: "/location"
    },
    {
        path: '/location',
        name: 'Location',
        component: () => import(
            /* webpackChunkName: "location" */
            '../views/LocationView.vue'
        )
    },
    {
        path: '/room-type',
        name: 'Room Type',
        component: () => import(
            /* webpackChunkName: "room-type" */
            '../views/RoomTypeView.vue'
        )
    },
    {
        path: '/booking-details',
        name: 'Booking Details',
        component: () => import(
            /* webpackChunkName: "booking-details" */
            '../views/BookingDetailsView.vue'
        )
    },
    {
        path: '/personal-details',
        name: 'Personal Details',
        component: () => import(
            /* webpackChunkName: "personal-details" */
            '../views/PersonalDetailsView.vue'
        )
    },
    {
        path: '/payment',
        name: 'Payment',
        component: () => import(
            /* webpackChunkName: "payment" */
            '../views/PaymentView.vue'
        )
    },
    {
        path: '/complete',
        name: 'Complete',
        component: () => import(
            /* webpackChunkName: "complete" */
            '../views/CompleteView.vue'
        )
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
});

export default router;
